<template>
  <form @submit="onSubmit" class="add-form">
    <div class="form-control">
      <label>Todo</label>
      <input
        autocomplete="off"
        type="text"
        v-model="text"
        name="text"
        placeholder="Add Todo"
      />
    </div>
    <div class="form-control">
      <label> DeadLine</label>
      <input
        autocomplete="off"
        type="text"
        v-model="day"
        name="day"
        placeholder="Add Day & Time"
      />
    </div>
    <div class="form-control form-control-check">
      <label>Set Reminder</label>
      <input type="checkbox" v-model="reminder" name="reminder" />
    </div>

    <input type="submit" value="Save Todo" class="btn btn-block save" />
  </form>
</template>

<script>
export default {
  name: "AddTask",
  data() {
    return {
      text: "",
      day: "",
      reminder: "",
    };
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();

      if (!this.text) {
        alert("请输入Todo!");
      } else {
        const newTask = {
          id: Math.floor(Math.random() * 1000000),
          text: this.text,
          day: this.day || "Today",
          reminder: this.reminder,
        };
        this.$emit("add-task", newTask);

        (this.text = ""), (this.day = ""), (this.reminder = false);
      }
    },
  },
};
</script>

<style scoped>
.add-form {
  margin-bottom: 40px;
}
.form-control {
  margin: 20px 0;
}
.form-control label {
  display: block;
}
.form-control input {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
}
.form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-control-check label {
  flex: 1;
}
.form-control-check input {
  flex: 2;
  height: 20px;
}

.save {
  transition: 300ms;
}
.save:hover {
  background-color: rgb(174, 172, 172);
  color: black;
  font-size: large;
  font-weight: bold;
}
</style>
